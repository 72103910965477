import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'modalGral',
    standalone: true,
})
export class ModalGralPipe implements PipeTransform {
    private titleCasePipe: TitleCasePipe = new TitleCasePipe();

    transform(value: any, ...args: string[]): string {
        if (!value) {
            return '';
        }

        switch (args[0]) {
            case 'img-empty':
                return value === '' ? 'assets/img/no-image.svg' : value;

            case 'title':
                return this.titleCasePipe.transform(value.title);

            case 'message':
                return value.message;

            case 'btnText':
                return this.titleCasePipe.transform(value.btnText);

            case 'img':
                return value.img;

            default:
                return '';
        }
    }
}
