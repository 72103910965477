import { Location, NgOptimizedImage } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { DataButton } from '@app/models/data-button.model';
import { ModalGralPipe } from '@app/pipes/modal-gral/modal-gral.pipe';

@Component({
    selector: 'app-button',
    standalone: true,
    imports: [RouterLink, NgOptimizedImage],
    templateUrl: './button.component.html',
    styleUrl: './button.component.sass',
})
export class ButtonComponent implements OnInit {
    @Output() notify = new EventEmitter();
    @Input() data: DataButton = {
        title: 'Put button title',
        link: '',
        type: 'routerLink',
        style: '',
        disabled: false,
        uppercase: false,
        customClass: '',
        rounded: false,
        width: 'auto',
        minWidth: '150px',
        block: false,
        id: 'none',
        imagePath: '',
    };
    @Input() buttonStyle: string;
    public modalGralPipe: ModalGralPipe;
    private location = inject(Location);

    constructor() {
        this.buttonStyle = 'bg-gray white-color';
        this.modalGralPipe = new ModalGralPipe();
    }

    ngOnInit() {
        this.applyStyles();
    }

    private applyStyles(): void {
        this.colorSelection();
        this.setDisableButton();
        this.setUppercaseButton();
        this.setCustomClassButton();
    }

    public goBack(): void {
        const currentUrl = this.location.path();
        const isValidUrl = currentUrl.lastIndexOf('/') > 0;
        if (isValidUrl) {
            this.location.back();
        }
    }

    private setDisableButton(): void {
        this.buttonStyle = this.data.disabled
            ? `${this.buttonStyle} disable`
            : this.buttonStyle;
    }

    private setCustomClassButton(): void {
        if (this.data.customClass !== 'undefined') {
            this.buttonStyle = `${this.buttonStyle} ${this.data.customClass}`;
        }
        if (this.data.rounded) {
            this.buttonStyle = `${this.buttonStyle} btn-rounded`;
        }
        if (this.data.block) {
            this.buttonStyle = `${this.buttonStyle} d-block`;
        }
    }

    private setUppercaseButton(): void {
        this.buttonStyle = this.data.uppercase
            ? `${this.buttonStyle} uppercase`
            : this.buttonStyle;
    }

    private colorSelection(): void {
        const stylesMapping: Record<string, string> = {
            primary: 'green-bg green-bg-hover white-color',
            secondary: 'purple-bg purple-bg-hover white-color',
            third: 'carbon-bg carbon-bg-hover white-color',
            danger: 'red-bg red-bg-hover white-color',
            giveMeAHand: 'yellow-bg yellow-bg-hover white-color',
            giveYouAHand: 'pink-bg pink-bg-hover white-color',
            cookieSetting: 'pink-cookie-bg white-bg-hover white-color',
        };

        const selectedStyle =
            stylesMapping[this.data?.style || ''] ||
            this.data?.style ||
            this.buttonStyle;
        this.buttonStyle = selectedStyle || this.buttonStyle;
    }
}
